import { useContext } from "react";
import {
  PersonCircle,
  Speedometer,
  BoxArrowInRight,
  BoxArrowUpRight,
} from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { DOCS_URL, CHANGELOG_URL } from "@utils/constant";
import { isDev } from "@utils/env";
import {
  ROUTE_DASHBOARD,
  ROUTE_LOGIN,
  ROUTE_PLAYGROUND,
  ROUTE_PROFILE,
} from "@constants/routes";

const SMALL_ICON_SIZE = 12;
const BIG_ICON_SIZE = 16;

function Header() {
  const user = useContext(AuthContext);
  const location = useLocation();
  const isDashboardRoute = location.pathname === ROUTE_DASHBOARD;
  const isProfileRoute = location.pathname === ROUTE_PROFILE;

  return (
    <header className="body-font border-b text-gray-600">
      <div className="flex flex-col flex-wrap items-center p-5 md:flex-row">
        <Link
          to="/"
          className="title-font mb-4 flex items-center font-medium text-gray-900 md:mb-0"
        >
          <span className="text-md flex items-center justify-center font-bold">
            <img
              className="mr-2 h-8"
              src={process.env.PUBLIC_URL + "/logo/sm.png"}
              alt="Copicake"
            />
            <span>Copicake</span>
            {isDev ? (
              <span className="-mt-2 ml-1 text-xs font-extrabold text-red-400">
                Dev
              </span>
            ) : (
              <></>
            )}
          </span>
        </Link>
        <a
          className="mt-4 inline-flex items-center space-x-2 rounded border-0 py-1 px-3 focus:outline-none md:mt-0"
          href={CHANGELOG_URL}
          target="_blank"
          rel="noreferrer"
        >
          <span>Changelog</span>
          <BoxArrowUpRight size={SMALL_ICON_SIZE} />
        </a>
        <a
          className="mt-4 inline-flex items-center space-x-2 rounded border-0 py-1 px-3 focus:outline-none md:mt-0"
          href={DOCS_URL}
          target="_blank"
          rel="noreferrer"
        >
          <span>API Docs</span>
          <BoxArrowUpRight size={SMALL_ICON_SIZE} />
        </a>
        <Link
          to={ROUTE_PLAYGROUND}
          className="mt-4 inline-flex items-center rounded border-0 py-1 px-3 focus:outline-none md:mt-0"
        >
          <span>Playground</span>
        </Link>
        <nav className="flex flex-wrap items-center justify-center md:ml-auto">
          <div className="flex space-x-2">
            {user && user.emailVerified ? (
              <>
                {!isDashboardRoute && (
                  <Link to={ROUTE_DASHBOARD}>
                    <button className="mt-4 inline-flex items-center space-x-2 rounded border-0 bg-gray-100 py-1 px-3 hover:bg-gray-200 focus:outline-none md:mt-0">
                      <Speedometer size={BIG_ICON_SIZE} />
                      <span>Dashboard</span>
                    </button>
                  </Link>
                )}
                {!isProfileRoute && (
                  <Link to={ROUTE_PROFILE}>
                    <button className="mt-4 inline-flex items-center space-x-2 rounded border-0 bg-gray-100 py-1 px-3 hover:bg-gray-200 focus:outline-none md:mt-0">
                      <PersonCircle size={BIG_ICON_SIZE} />
                      <span>Profile</span>
                    </button>
                  </Link>
                )}
              </>
            ) : (
              <>
                <Link to={ROUTE_LOGIN}>
                  <button className="mt-4 inline-flex items-center space-x-2 rounded border-0 bg-gray-100 py-1 px-3 hover:bg-gray-200 focus:outline-none md:mt-0">
                    <BoxArrowInRight size={BIG_ICON_SIZE} />
                    <span>Login</span>
                  </button>
                </Link>
              </>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
