import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createTemplate,
  deleteTemplate,
  duplicateTemplate,
} from "@apis/template";
import { AppContext } from "../context/AppContext";
import { Template } from "../type";
import Header from "@components/Header";
import NoTemplate from "@components/Dashboard/NoTemplate";
import TemplateItem from "@components/Dashboard/TemplateItem";
import AddTemplateItem from "@components/Dashboard/AddTemplateItem";
import AddTemplateModal from "@components/Modals/AddTemplateModal/AddTemplateModal";
import ConfirmModal from "@components/Modals/ConfirmModal";
import TemplateFilter from "@components/Dashboard/TemplateFilter";
import { ROUTE_EDIT } from "@constants/routes";

function Dashboard() {
  const { templates, setTemplates } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false);
  const [isAddTemplateModalShown, setIsAddTemplateModalShown] = useState(false);
  const [toRemoveTemplateId, setToRemoveTemplateId] = useState("");
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const filteredTemplates = getFilteredTemplates();

  function getFilteredTemplates() {
    if (!keyword) {
      return templates.sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    }

    return templates.filter((template) => {
      return template.title.toLowerCase().includes(keyword.toLowerCase());
    });
  }

  return (
    <div className="flex h-full flex-col">
      <Header />
      <div className="flex-auto bg-gray-100">
        <div className="flex h-full flex-col p-5">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="mr-2 text-2xl font-bold">Templates</h1>
            <div className="flex space-x-2">
              <TemplateFilter
                onFilter={(newKeyword) => {
                  setKeyword(newKeyword);
                }}
              />
              <AddTemplateItem
                onClick={async () => {
                  setIsAddTemplateModalShown(true);
                }}
              />
            </div>
          </div>
          {filteredTemplates.length === 0 && (
            <div className="flex flex-auto flex-wrap">
              <div className="flex flex-auto items-center justify-center">
                <NoTemplate />
              </div>
            </div>
          )}
          {filteredTemplates.length !== 0 && (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
              {filteredTemplates.map((template) => {
                return (
                  <TemplateItem
                    template={template}
                    key={template.id}
                    onRemove={async (templateId) => {
                      if (isLoading) {
                        return;
                      }
                      setToRemoveTemplateId(templateId);
                      setIsConfirmModalShown(true);
                    }}
                    onDuplicate={async (templateId) => {
                      if (isLoading) {
                        return;
                      }
                      setIsLoading(true);
                      toast.promise(duplicateTemplate({ templateId }), {
                        pending: "Duplicating template...",
                        success: {
                          render({ data }: { data: { data: Template } }) {
                            setIsLoading(false);
                            const foundTemplate = data?.data;
                            setTemplates([foundTemplate, ...templates]);
                            return "Template created!";
                          },
                        },
                        error: {
                          render({ data }) {
                            setIsLoading(false);
                            return (data as Error).message;
                          },
                        },
                      });
                    }}
                    onOpen={(templateId) => {
                      if (isLoading) {
                        return;
                      }

                      navigate(`${ROUTE_EDIT}/${templateId}`);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        header="Are you sure you want to delete this template?"
        open={isConfirmModalShown}
        onCancel={() => {
          setIsConfirmModalShown(false);
        }}
        onSubmit={() => {
          setIsConfirmModalShown(false);
          setIsLoading(true);

          const templateId = toRemoveTemplateId;
          toast
            .promise(deleteTemplate(templateId), {
              pending: "Removing template...",
              success: {
                render({ data }: { data: { data: {} } }) {
                  setTemplates((prevState) => {
                    return prevState.filter(
                      (prevTemplate) => prevTemplate.id !== templateId
                    );
                  });
                  return "Template removed!";
                },
              },
              error: {
                render({ data }) {
                  return (data as Error).message;
                },
              },
            })
            .finally(() => {
              setToRemoveTemplateId("");
              setIsLoading(false);
            });
        }}
      />
      <AddTemplateModal
        open={isAddTemplateModalShown}
        onCancel={() => {
          setIsAddTemplateModalShown(false);
        }}
        onSubmit={(templateInfo) => {
          setIsLoading(true);
          setIsAddTemplateModalShown(false);

          toast.promise(createTemplate(templateInfo), {
            pending: "Creating template...",
            success: {
              render({ data }: { data: { data: Template } }) {
                setIsLoading(false);
                const foundTemplate = data?.data;
                setTemplates([foundTemplate, ...templates]);
                return "Template created!";
              },
            },
            error: {
              render({ data }) {
                setIsLoading(false);
                return (data as Error).message;
              },
            },
          });
        }}
      />
    </div>
  );
}

export default Dashboard;
